/* eslint-disable */
import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import { mobileNavigation } from './mNavigation';
mobileNavigation();

// SELECTORS
const links = document.querySelectorAll('.m-navigation__link');
const form = document.querySelector('.header-box__form');

// DELEGATION
for (let i = 0; i < links.length; i++)
  links[i].addEventListener('click', function() {
    document.getElementById('navi-toggle').checked = false;
    document.querySelector('.m-navigation__list').classList.toggle('hidden');
  });

// document.getElementById('navi-toggle').addEventListener('change', function() {
//   document.querySelector('.m-navigation__list').classList.toggle('hidden');
// });

const scrollDist =
  document.getElementById('home').scrollHeight +
  document.querySelector('.nav').scrollHeight;
window.onscroll = function() {
  if (
    document.body.scrollTop > scrollDist ||
    document.documentElement.scrollTop > scrollDist
  ) {
    document.querySelector('.nav').classList.add('fixed-top');
    document.querySelector('.filler').classList.remove('none');
  } else {
    document.querySelector('.nav').classList.remove('fixed-top');
    document.querySelector('.filler').classList.add('none');
  }
};

// document.querySelector('.btn--cta').addEventListener('click', async e => {
//   // e.preventDefault();
//   console.log('clicked');
//
//   try {
//     const res = await fetch('submitForm', {
//       headers: { 'Content-Type': 'application/json' },
//       method: 'POST',
//       body: JSON.stringify({ name: 'David Kelly' }),
//     });
//
//     const resData = await res.json();
//
//     console.log(resData);
//   } catch (error) {
//     console.error(error);
//   }
// });

form.addEventListener('submit', async e => {
  e.preventDefault();

  const formDataPre = Object.values(form);

  const x = formDataPre.map(el => {
    console.log(el.value);
    console.log(el.previousElementSibling.outerText);

    if (el.value === 'Claim Now') {
      return;
    }
    let fieldName = el.previousElementSibling.outerText.toLowerCase();

    if (fieldName === 'phone number') {
      fieldName = 'phoneNumber';
    }

    return { [fieldName]: el.value };
  });

  const formData = Object.assign({}, ...x);

  try {
    const res = await fetch('submitForm', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(formData),
    });

    const resData = await res.json();

    console.log(resData);
  } catch (error) {
    console.error(error);
  }

  location.reload();
});
